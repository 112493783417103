import VTooltip from 'v-tooltip'
import Vue from 'vue'
import VueMq from 'vue-mq'

import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import TourStep from '@/components/molecules/TourStep.vue';
import Tour from '@/components/organisms/Tour.vue';
import moment from 'moment'
import { singular } from 'pluralize'

Vue.config.productionTip = false
Vue.config.devtools = true

// This is the vue component performance profiler
// It tracks render times of components
// it should be disabled
// import VueComponentProfile from '@/plugins/vue-component-profile';
// const profileDomains = ['localhost', 'dev.creatorvelocity'];
// const useProfiler = profileDomains.some(url => window.location.href.includes(url));
// if(useProfiler){
// 	console.log('Using component profiler')
// 	Vue.use(VueComponentProfile);
// }

Vue.use(VTooltip, {
  popover: {
    defaultHandleResize: false,
	defaultDelay: {
		show: 0,
		hide: 100
	}
  }
})
Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    mobile: 600,
    md: 800,
    lg: 1000,
    xl: Infinity
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})

window.debounce = function(fn, delay, immediate) {
  let timeoutID = null;
  return function (...args) {
    const later = () => {
      timeoutID = null;
      if (!immediate) {
        fn.apply(this, args);
      }
    };
    const callNow = immediate && !timeoutID;
    clearTimeout(timeoutID);
    timeoutID = setTimeout(later, delay);
    if (callNow) {
      fn.apply(this, args);
    }
  };
};


Vue.directive('blank', {
  inserted(el) {
    const anchors = el.querySelectorAll('a');
    anchors.forEach(anchor => {
      anchor.setAttribute('target', '_blank');
      anchor.setAttribute('rel', 'noopener noreferrer'); // for security reasons
    });
  }
});

Vue.use({
  install (Vue) {
    Vue.component(Tour.name, Tour)
    Vue.component(TourStep.name, TourStep)
    // Object containing Tour objects (see VTour.vue) where the tour name is used as key
    Vue.prototype.$tours = {}
  }
});

window.app = new Vue({
  router,
  store,
  data(){
    return {
      tours: {
        app: [
          {
            target: '#tour-watch-tour',
            header: {
              title: 'Watch a tour',
            },
            content: `<p>Discover the features of Creator Velocity in this guided tour.</p>`,
            params: {
              placement: 'bottom',
              highlight: false
            }
          },
          {
            target: '#tour-nav-search',
            header: {
              title: 'Start Your Search Here',
            },
            content: `<p>Search for creators to collaborate with.</p>`,
            params: {
              placement: 'right',
              highlight: true
            },
            async before(){
              if(router.currentRoute.name !== 'home'){
                await router.push({name: 'home'})
                await Vue.nextTick()
              }
            }
          },
          {
            target: '#search-container',
            header: {
              title: 'Search by username or keyword.',
            },
            content: `<p>Looking for a certain creator? Find them here.</p>`,
            params: {
              placement: 'bottom',
              highlight: false
            },
            async before(){
              if(router.currentRoute.name !== 'topics'){
                await router.push({name: 'topics'})
                // wait for 1 second for transition to complete
                await new Promise(resolve => setTimeout(resolve, 500));
              }
            }
          },
          {
            target: '#tour-topics',
            header: {
              title: 'Search by Topic',
            },
            content: `<p>Find creators that match your niche.</p>`,
            params: {
              placement: 'top',
              highlight: false
            },
            async before(){
              if(router.currentRoute.name !== 'topics'){
                await router.push({name: 'topics'})
              }
            }
          },
          {
            target: '#tour-filter-search-results',
            header: {
              title: 'Refine Your Search',
            },
            content: `<p>Quickly zero in on the perfect influencers for your needs.</p>`,
            params: {
              placement: 'bottom',
              highlight: true
            },
            async before(){
              if(router.currentRoute.name !== 'creators'){
                await router.push({name: 'creators', query: {query: 'guitar'}})
                // wait for 1 second for transition to complete
                await new Promise(resolve => setTimeout(resolve, 500));
              }
            }
          },
          {
            target: '#tour-first-creator',
            header: {
              title: 'See Creator Details',
            },
            content: `<p>View creators niches, style of content, influence, pricing, and more.</p><p>Click any creator for more details.</p>`,
            params: {
              placement: 'right',
              highlight: true
            },
            async before(){
              if(router.currentRoute.name !== 'creators'){
                await router.push({name: 'creators', query: {query: 'guitar'}})
                // wait for 1 second for transition to complete
                await new Promise(resolve => setTimeout(resolve, 500));
              }
            }
          },
          {
            target: '#tour-first-creator .add-to-project',
            header: {
              title: 'Add candidates to Projects',
            },
            content: `<p>Keep track of potential collaborators by adding them to projects.</p>`,
            params: {
              placement: 'right',
              highlight: true,
              enableScrolling: false
            },
            async before(){
              if(router.currentRoute.name !== 'creators'){
                await router.push({name: 'creators', query: {query: 'guitar'}})
                // wait for 1 second for transition to complete
                await new Promise(resolve => setTimeout(resolve, 500));
              }
            }
          },
        ],
        // projects: [
        //   {
        //     target: '#tour-topics',
        //     header: {
        //       title: 'Search by Topic',
        //     },
        //     content: `<p>Find creators that match your niche.</p>`,
        //     params: {
        //       placement: 'top',
        //       highlight: false
        //     },
        //     async before(){
        //       if(router.currentRoute.name !== 'topics'){
        //         await router.push({name: 'topics'})
        //       }
        //     }
        //   },
        // ]
      },
      clientWidth: 0,
    }
  },
  computed: {
    body(){
      return document.querySelector('body');
    },
    serverId(){
        return this.$store.state.app.env
    },
    isProduction(){
      return this.serverId === 'production';
    },
	isStaging(){
		return this.serverId === 'staging';
	},
    isMobile (): boolean {
      return this.clientWidth < 600
    },
	isPrintMode(): boolean {
		return this.$route.query.print === 'true';
	}
  },
  mounted(){
    window.onresize = this.onClientResize
    this.onClientResize()
  },
  methods: {
    compactNumber: n => {
      if (n < 1e3) return Math.max(Math.round(n), 0);
      if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
      if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
      if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
      if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    },
	secondsToTime(seconds: number): string {
		const duration = moment.duration(seconds, 'seconds');
		const { years, months, days, hours, minutes } = (duration as any)._data;
		return `${years ? `${years}y ` : ''}${months ? `${months}m ` : ''}${days ? `${days}d ` : ''}${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m` : ''}`
	},
    open: (link): void => {window.open(link)},
    scrollTo(position, smooth = true){
      this.$nextTick(() => {
        (document.querySelector('.app-content') || window).scrollTo({
          left: 0,
          top: position,
          behavior: smooth ? 'smooth' : 'auto'
        });
      });
    },
    onClientResize () {
      this.clientWidth = Math.max(
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      )
    },
    getScrollElement(){
      return document.querySelector('.app-content');
    },
    callAppMethod(method, args){
      try{
        return this.$children[0][method](args)
      }catch (e){
        return e
      }
    },
    copyToClipboard(text){
        const el = document.createElement('textarea');
        el.value = text;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    },
	singularize(str) {
		return singular(str)
	}
  },
  render: h => h(App)
}).$mount('#app')
