<template>
	<div :class="{
	  'nav-container': true,
	  'sidebar-mode': !isMobile,
	  'footer-mode': isMobile,
	  expanded
	}">
	  <div class="nav-content">
		<!-- Only show in sidebar mode -->
		<template v-if="!isMobile">
		  <router-link
			:to="{name: 'home'}"
			style="text-align: center"
		  >
			<TheLogo
			  v-show="expanded"
			  color="white"
			/>
			<img
			  v-show="!expanded"
			  src="/img/creator-velocity-icon.png"
			  width="40%"
			  style="margin:0 auto 1em;"
			  alt="Creator Velocity"
			>
		  </router-link>
		</template>
  
		<!-- Common navigation items -->
		<nav>
		  <PopoverMenu
			:placement="isMobile ? 'top' : 'right-start'"
			class="account-selector"
			popover-class="scrollable"
		  >
			<NavigationMenuLink
			  v-if="selectedAccount"
			  slot="trigger"
			  icon="selfie"
			  :image="selectedAccount.logo"
			  :title="selectedAccount.name"
			/>
			<!-- Account selection menu items -->
			<div style="text-align: right" v-if="$store.getters['user/isAdmin']">
			  <span class="size-xxs uppercase grayMedium">Show test accounts</span>
			  <InputToggle
				v-model="showTestAccounts"
				@change="showTestAccounts=$event"
				:height="20"
				:width="48"
				:font-size="10"
				color="orange"
				:orphan="true"
				:labels="{ checked: 'Yes', unchecked: 'No' }"
			  />
			</div>
			<PopoverMenuItem
			  v-for="(brand, index) in filteredBrands"
			  :key="index"
			  v-close-popover
			  :image="brand.logo"
			  :label="brand.name"
			  class="small"
			  @click.native="$store.commit('account/SET_SELECTED_ACCOUNT', brand._id)"
			/>
			<div v-if="isMobile" style="padding: 1em">
				<HorizontalRule /><br />
				<NavigationUser :dropdown="false" />
			</div>
		  </PopoverMenu>
		  <NavigationMenuLink
			v-if="$store.getters['account/isBrand']"
			:to="{name: 'home'}"
			icon="home"
			title="Dashboard"
		  />
		  <NavigationMenuLink
			id="tour-nav-search"
			v-if="$store.getters['account/isBrand']"
			:to="{name: hasTopicsSelected ? 'creators' : 'topics'}"
			icon="magnifying-glass"
			title="Find Creators"
		  />
		  <NavigationMenuLink
			id="tour-nav-projects"
			v-if="$store.getters['account/isBrand']"
			:to="{name: 'projects'}"
			icon="favorites"
			title="Projects"
		  />
		  <NavigationMenuLink
			:to="{name: 'payments-and-taxes'}"
			icon="dollar-light"
			title="Payments & Taxes"
		  />
		  <NavigationMenuLink
			:to="{name: 'account-settings'}"
			icon="storefront-inverted"
			title="Account"
		  />
		  <NavigationMenuLink
		  	v-if="isMobile"
			:to="{name: 'notifications'}"
			icon="bell"
			title="Notifications"
		  >
		  	<span class="badge" v-if="$store.getters['notification/unreadCount']">{{ $store.getters['notification/unreadCount']}}</span>
		  </NavigationMenuLink>
		  <PopoverMenu style="order: 1" popover-class="dark" v-if="isMobile && $store.getters['user/canManage']">
			<NavigationMenuLink
			  slot="trigger"
			  icon="ellipsis"
			/>
				<NavigationMenuLink
					v-for="(item, index) in managerMenuItems"
					:key="index"
					:to="item.to"
					:icon="item.icon"
					:title="item.title"
					class="expanded"
				/>
			<div v-if="$store.getters['user/isAdmin']">
				<NavigationMenuLink
				v-for="(item, index) in adminMenuItems"
				:key="index"
				:to="item.to"
				:icon="item.icon"
				:title="item.title"
				class="expanded"
				@click.native="item.click"
				/>
			</div>
		  </PopoverMenu>
		  <!-- Admin menu - only show in sidebar mode -->
			<div v-if="$store.getters['user/canManage']" class="manager-menu-container">
				<hr style="border-color: #666;">
				<NavigationMenuLink
				v-for="(item, index) in managerMenuItems"
				:key="index"
				:to="item.to"
				:icon="item.icon"
				:title="item.title"
				class="expanded"
				/>
			</div>
		  <div
			v-if="$store.getters['user/isAdmin'] && !isMobile"
			class="admin-menu-container"
		  >
			<!-- Admin menu items -->
			<NavigationMenuLink
			  v-for="(item, index) in adminMenuItems"
			  :key="index"
			  :to="item.to"
			  :icon="item.icon"
			  :title="item.title"
			  @click.native="item.click"
			  class="expanded"
			/>
		  </div>
  
		  <!-- Expand/collapse button - only in sidebar mode -->
		  <NavigationMenuLink
			v-if="!isMobile"
			:to="null"
			icon="chevron"
			:icon-class="{'rotate-180': expanded}"
			:title="allowExpand ? 'Collapse menu' : 'Expand Menu'"
			@click.native="allowExpand = !allowExpand"
		  />
		</nav>
  
		<!-- Version info - only in sidebar mode -->
		<div v-if="!isMobile" class="bottom">
		  <div>
			<v-popover trigger="hover" placement="right">
			  <p class="size-xxs grayMedium" @click="copyServerData">
				v{{ $store.state.app.version}}
			  </p>
			  <template slot="popover">
				<div @click="copyServerData">
				  <strong>Click to Copy</strong><br />
				  Environment: {{ $store.state.app.env }}<br />
				  Version: {{ $store.state.app.version}}<br />
				  Build: {{ $store.state.app.build }}<br />
				  Start time: {{ $store.getters['app/getServerStartTime'] }}<br />
				  Browser: {{ browser }}
				</div>
			  </template>
			</v-popover>
		  </div>
		</div>
	  </div>
	</div>
  </template>
  
  <script lang="ts">
  import Vue from 'vue'
  import { computed } from 'vue'
  import Button from '@/components/atoms/Button.vue'
  import NavigationMenuLink from '@/components/atoms/NavigationMenuLink.vue'
  import TheLogo from "@/components/atoms/TheLogo.vue"
  import EntityProfileName from "@/components/molecules/EntityProfileName.vue"
  import PopoverMenu from "@/components/molecules/PopoverMenu.vue"
  import PopoverMenuItem from "@/components/molecules/PopoverMenuItem.vue"
  import InputToggle from '../atoms/InputToggle.vue'
  import NavigationUser from '@/components/atoms/NavigationUser.vue'
  import HorizontalRule from '@/components/atoms/HorizontalRule.vue'
  
  export default Vue.extend({
	components: {
	  PopoverMenuItem,
	  PopoverMenu,
	  EntityProfileName,
	  Button,
	  NavigationMenuLink,
	  TheLogo,
	  InputToggle,
	  NavigationUser,
	  HorizontalRule
	},
	data() {
	  return {
		allowExpand: false,
		showTestAccounts: false,
	  }
	},
	provide() {
	  return {
		expanded: computed(() => this.expanded)
	  }
	},
	computed: {
		expanded(){
			return this.allowExpand && !this.$root.isMobile
		},
		isMobile(){
			return this.$root.isMobile
		},
	  hasTopicsSelected() {
		try {
		  return this.$store.state.account.creatorFilterSettings.contentTopics.length > 0
		} catch(e) {
		  return false
		}
	  },
	  selectedAccount() {
		return this.$store.getters['account/selected']
	  },
	  filteredBrands() {
		return this.$store.getters['account/brandsForUser']
		  .sort((a,b) => a.name.localeCompare(b.name))
		  .filter(a => !this.showTestAccounts && !a.isTestAccount || this.showTestAccounts)
	  },
	  managerMenuItems() {
		return [
			{ to: {name: 'creators-to-review'}, icon: 'selfie', title: 'Creators to review' }
		]
	  },
	  adminMenuItems() {
		return [
		  { to: {name: 'brands'}, icon: 'storefront-inverted', title: 'Brands' },
		  { to: {name: 'media-outliers', params: { factor: 'by-average-views'}}, icon: 'play', title: 'Outliers' },
		  { to: {name: 'vision-profiles'}, icon: 'eye', title: 'Vision Profiles' },
		  { to: {name: 'vision-reports'}, icon: 'graph', title: 'Vision Reports' },
		  { to: {name: 'media'}, icon: 'report', title: 'Media Errors' },
		  { to: {name: 'app-config'}, icon: 'gear', title: 'Global Config' },
		  { to: null, icon: 'gear', title: 'Profiler', click: () => this.$store.commit('app/SET_SHOW_PROFILER', !this.$store.state.app.showProfiler) },
		  { to: {name: 'app-queue'}, icon: 'clock', title: 'Queue' },
		  { to: {name: 'errors'}, icon: 'warn', title: 'Errors' }
		]
	  },
		browser(){
			// get the javasceipt btowser form the user agent
			try{
				const browser = (function(){
				let ua= navigator.userAgent, tem, M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
				if(/trident/i.test(M[1])){
					tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
					return 'IE '+(tem[1] || '');
				}
				if(M[1]=== 'Chrome'){
					tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
					if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
				}
				M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
				if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
				return M.join(' ');
			})();
			return browser;
			} catch(e){
				return 'unknown'
			}
		}
	},
	methods: {
	  copyServerData() {
		this.$root.copyToClipboard(`
		  Environment: ${this.$store.state.app.env}
		  Version: ${this.$store.state.app.version}
		  Build: ${this.$store.state.app.build}
		  Start time: ${this.$store.getters['app/getServerStartTime']}
		  Browser: ${this.browser}
		`)
	  }
	}
  })
  </script>
<style lang="less" scoped>
  @import '../../less/variables';


  .nav-container {

    background: @black;
  &.sidebar-mode {
    flex-shrink: 0;
    height: 100vh;
    position: relative;
    display: flex;
    z-index: 2;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    &::-webkit-scrollbar {
      display: none;
    }

    &, .nav-content {
      width: 70px;
	  padding-top: 1.5em;
    }

    &.expanded {
      width: 220px;
      .nav-content {
        width: 220px;
      }
    }

	.nav-menu-item{

		margin: 0 -1rem;
	}
  }

  &.footer-mode {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    height: @mobile-footer-height;
	z-index: 50;
	box-sizing: border-box;

    .nav-content {
      height: 100%;
      nav {
        height: 100%;
        flex-direction: row;
        padding: 0;
        margin: 0;
      }
    }


	.account-selector{
		order: 2;
		font-size: 1rem;
	}

    /deep/ .navigation-menu-link, /deep/ .v-popover {
      flex: 1;
      height: 100%;
      padding: 0;
      justify-content: center;
      border-top: 3px solid transparent;
	  display: flex;
	  align-items: center;
	  box-sizing: border-box;

      .icon {
        height: 1.5rem !important;
        width: 1.5rem !important;
      }

      .title {
        display: none;
      }

    }
  }
}

.nav-content {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;
  -webkit-app-region: drag;
  box-sizing: border-box;
  justify-content: flex-start;
}
  nav{
    padding: 1px 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    font-size: 0.8em;
  }

  .sidebar-container{
    flex-shrink: 0;
    height: 100vh;
    position: relative;
    display: flex;
    z-index: 2;
    //transition: width 1s;
    background: @black;
    overflow: scroll;
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Webkit browsers */
    }
  }
  .v-tour--active .sidebar-container{
    overflow: visible;
  }

  .sidebar-content{
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 1.5em;
    user-select: none;
    -webkit-app-region: drag;
    box-sizing: border-box;
    justify-content: flex-start;
  }

  .sidebar-container, .sidebar-content{
    width: 70px;
  }

  /deep/ .v-select{
    margin: 0.75em;
    padding: 0 0.5em;
    border-radius: 27.5px;
    transition: background-color 150ms;
    &:hover, &:active{
      background: fade(@white, 10%);
    }
  }

  /deep/ .vs__selected{
    color: @grayLight
  }

  .account-name /deep/ .entity-details{
    display: none;
  }

  button.circle {
    margin:1em auto;
    padding: 0;
    height: auto;
    width: auto;
    /deep/ .icon{
      color: @black;
      border-radius: 5px;
      background: @grayMedium;
      font-size: 0.8em;
      padding: 0.2em;
      &:hover{
        background: @grayLightMedium
      }
    }
  }


  .account-selector /deep/ .trigger{
    width: 100%;
    text-align: center;
  }

  .admin-menu-container p{
    display: none;
  }

  .expanded button.circle{
    position: absolute;
    top: 5px;
    right: 5px;
    margin-top: 0;
  }

  .bottom{
    text-align: center;
    padding: 0 1rem 1rem;
    margin-top: auto;
    h2 {
      color: @grayMedium;
      font-weight: 300 !important;
    }
    img {
      width: 50%;
    }
  }

  .the-logo{
    margin:0 auto 0.5em;
    width: 50%;
  }

  /deep/ .vs__actions{
    display: none;
  }

  .sidebar-container.expanded {
    width: 220px;
    .sidebar-content{
      width: 220px;
    }
    .account-name{
      justify-content: flex-start !important;
      margin-left: 1.5em;
    }
    .account-name /deep/ .entity-details{
      margin-left: 1em;
      display: block;
    }
    .bottom{
      display: block;
    }
    /deep/ .vs__actions{
      display: flex;
    }
    .admin-menu-container p{
      display: block;
    }
  }
</style>
