import axios from 'axios'
import deepmerge from 'deepmerge';
import Vue from 'vue'

import baseModule from './base-module'

export default baseModule({
  name: 'users',
  state: {
    me: {},
    users: {},
    facebookEntities: []
  },
  mutations: {
    SET_ME (state, user) {
      state.me = user
    },
    UNSET_ME (state) {
      state.me = {}
    },
    SET (state, users) {
      users.forEach(user => {
        Vue.set(state.documents, user._id, user)
      })
    },
    UPDATE (state, user) {
      Vue.set(state.documents, user._id, user)
      Vue.set(state,'me', deepmerge(state.me, user))
    },
  },
  actions: {
    authenticate (context) {
      return new Promise((resolve, reject) => {
        if (context.getters.isAuthenticated) {
          resolve(true)
        } else {
          context.dispatch('loadMe')
            .then(() => {
              if (context.getters.isAuthenticated) {
                resolve(true)
              } else {
                reject()
              }
            })
            .catch(e => {
              reject()
              console.warn('auth error', e)
            })
        }
      })
    },
    loadMe (context) {
      return new Promise((resolve, reject) => {
        axios.get('/api/users/me')
          .then(function (response) {
            if (response.data && response.data._id) {
              context.commit('SET_ME', response.data)
            }
            resolve()
          })
          .catch(reject)
      })
    },
    // load (context) {
    //   return new Promise((resolve, reject) => {
    //     axios.get('/api/users')
    //       .then(function (response) {
    //         if (response.data) {
    //           context.commit('SET_BULK', response.data)
    //           resolve(response.data)
    //         }
    //       }).catch((e) => {
    //         console.warn('auth error', e)
    //         reject(e)
    //       })
    //   })
    // },
    login (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/users/login', payload).then(function (response) {
          context.commit('SET_ME', response.data)
          resolve()
        }).catch(error => {
          if (error.response.data && error.response.data.message) {
            reject(error.response.data.message)
          } else {
            reject('Unable to connect to server.')
          }
        })
      })
    },
    loginWithAuthCode (context, authCode) {
      return new Promise((resolve, reject) => {
        axios.post('/api/users/login-with-authcode', {authCode}).then(function (response) {
          console.log('Signed in with authcode', response.data)
          context.commit('SET_ME', response.data)
          resolve(response.data)
        }).catch(error => {
          if (error.response.data && error.response.data.message) {
            reject(error.response.data.message)
          } else {
            reject('Unable to connect to server.')
          }
        })
      })
    },
    loginWithPasswordResetToken (context, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/api/users/login/with-password-reset-token', payload).then(function (response) {
          context.commit('SET_ME', response.data)
          resolve()
        }).catch(error => {
          if (error.response.data && error.response.data.message) {
            reject(error.response.data.message)
          } else {
            reject('Unable to connect to server.')
          }
        })
      })
    },
    logout (context) {
      return axios.get('/api/users/logout').then(function () {
        context.commit('UNSET_ME')
      }).catch(e => {
        console.log(e)
      })
    },
    create (context, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            '/api/users/signup',
            data
          )
          .then(response => {
            if (response.data && response.data._id) {
              context.dispatch('loadMe').catch(e => console.error('Error loading me:', e));
            }
          })
          .catch(e => {
            if (e.response && e.response.data && e.response.data.message) {
              reject(e.response.data.message)
            } else {
              reject('Please try again later.')
            }
          })
      })
    },
    update (context, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            '/api/users/me',
            data
          )
          .then(response => {
            if (response.data && response.data._id) {
              context.dispatch('loadMe').catch(e => console.error('Error loading me:', e));
              // This updates the users collaboration
              context.dispatch('collaboration/load', {}, { root: true }).catch(e => console.error('Error loading collabs:', e));
              resolve(response.data)
            } else {
              reject(response.data)
            }
          })
          .catch(() => {
            reject()
          })
      })
    },
    updateFacebookPermissions (context, authObject) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/users/me/update-facebook-permissions', authObject)
          .then(response => {
            if (response.data && response.data._id) {
              context.commit('SET_ME', response.data)
              resolve(response.data)
            } else {
              reject(response.data)
            }
          })
          .catch(() => {
            reject()
          })
      })
    },
    requestPasswordReset(context, email){
      return new Promise((resolve, reject) => {
        axios
            .post(
                '/api/users/password-reset',
                { email }
            )
            .then(response => {
              if (response.data && response.data.success) {
                resolve(response.data)
              } else {
                reject(response.data)
              }
            })
            .catch(() => {
              reject()
            })
      })
    },
    updatePassword (context, password) {
      return new Promise((resolve, reject) => {
        axios
          .put(
            '/api/users/me/password',
            { password }
          )
          .then(response => {
            if (response.data && response.data._id) {
              resolve(response.data)
            } else {
              reject(response.data)
            }
          })
          .catch(() => {
            reject()
          })
      })
    },
    setPasswordWithCollaborationToken (context, payload) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/api/users/me/password',
          data: {
            password: payload.password,
            collaborationToken: payload.collaborationToken
          }
        })
          .then(response => {
            if (response.data && response.data._id) {
              context.commit('SET_ME', response.data)
              resolve(response.data)
            } else {
              reject(response.data)
            }
          })
          .catch(() => {
            reject()
          })
      })
    },
    addStripePaymentMethod (context, token) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/api/users/me/payment-method',
          data: {
            token
          }
        })
          .then(response => {
            if (response.data && response.data._id) {
              resolve(response.data)
            } else {
              reject(response.data)
            }
          })
          .catch(() => {
            reject()
          })
      })
    },
    getStripePortalSession () {
      return new Promise((resolve, reject) => {
        axios.get('/api/users/me/stripe-portal-session')
          .then(response => {
            if (response.data && response.data.url) {
              resolve(response.data)
            } else {
              reject(response.data)
            }
          })
          .catch(() => {
            reject()
          })
      })
    },
    delete (context) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            '/api/users/me'
          )
          .then(response => {
            context.commit('UNSET_ME')
            resolve(response.data)
          })
          .catch(() => {
            reject()
          })
      })
    },
    savePushSubscription: async function (context, subscription) {
      const response = await axios.post('/api/users/me/push-subscriptions', subscription)
      if (response.data && response.data._id) {
        context.commit('SET_ME', response.data)
      }
    },
    // disablePushSubscription: async function () {
    //   const registration = await navigator.serviceWorker.ready
    //   if (registration && registration.pushManager) {
    //     const subscription = await registration.pushManager.getSubscription()
    //     if (subscription) {
    //       return await subscription.unsubscribe()
    //     }
    //   }
    //   return true
    // }
  },
  getters: {
    all: state => Object.values(state.documents),
    byId: (state) => id => state.documents[id] || null,
    hasFacebookPermissions: (state, getters) => permissions => {
      return getters.me.facebookPermissions && permissions.every(permission => {
        return getters.me.facebookPermissions[permission] === true
      })
    },
    me: state => {
      return state.me
    },
    isAuthenticated: state => {
      return !!state.me._id
    },
    isAdmin: state => {
      return state.me.role === 'admin'
    },
	canManage: (state, getters) => {
		return getters.isAdmin || state.me.role === 'manager'
	},
    initials: (state) => {
      let initials
      try {
        initials = state.me.local.firstName.charAt(0) + state.me.local.lastName.charAt(0)
      } catch (e) {
        initials = ''
      }
      return initials
    },
    firstName: state => state.me.local.firstName || null,
    avatar: state => state.me.local.avatar || null,
    fullName: (state) => {
      let name
      try {
        name = `${state.me.local.firstName} ${state.me.local.lastName.charAt(0)}`.trim();
      } catch (e) {
        name = state.me.local.email
      }
      return name
    },
    nameById: state => id => {
      const user = state.documents[id]
      try{
        return (`${user.local.firstName} ${user.local.lastName}`.trim()) || user.local.email;
      }catch {
        return 'Unknown User'
      }
    }
  },
  onDeauthenticate (state) {
    state.me = {}
    state.documents = {}
    state.facebookEntities = []
  },
});