<template>
  <div class="form-dark">
	<div v-if="isProjectPerformancePage">
    	<div class="form-section">
			<div class="input-group">
				<label>Copy sharable URL</label>
				<div
					class="link-container"
					>
					<InputText
						:value="projectPerformanceLink"
					/>
					<Button
						class="small full-width"
						@click="copy(projectPerformanceLink)"
					>
						{{ copied ? 'Copied!' : 'Copy Link' }}
						<IconSvg
						v-if="copied"
						name="check"
						/>
					</Button>
					</div>
			</div>
		</div>
		<p class="grayMedium center">
			- or -
		</p>
	</div>
    <div class="input-group">
      <InputSelect
        placeholder="Select team member"
        :options="teamMembers"
        :value="recipient"
        @change="recipient=$event"
      />
    </div>
    <div v-if="showNewUserForm">
      <div class="form-section">
        <div class="input-group half">
          <label>First name</label>
          <InputText
            :value="newUser.firstName"
            name="firstName"
            @change="newUser.firstName = $event"
          />
        </div>
        <div class="input-group half">
          <label>Last name</label>
          <InputText
            :value="newUser.lastName"
            name="lastName"
            @change="newUser.lastName = $event"
          />
        </div>
        <div class="input-group single">
          <label>Email</label>
          <InputEmail
            :value="newUser.email"
            name="email"
            @change="newUser.email = $event"
          />
        </div>
      </div>
      <p class="small">
        <span class="bold">
          <IconSvg name="info"/>
           Notice:
        </span>
        Creating this link will invite a new team member, granting them access to your account and projects.
      </p>
      <Button
        label="Create Link"
        class="full-width"
        @click="createCollaboration"
				icon="link"
      />
    </div>
    <div
      v-if="link"
      class="link-container"
    >
      <InputText
        :value="link"
      />
      <Button
        class="small full-width"
        @click="copy(link)"
      >
        {{ copied ? 'Copied!' : 'Copy Link' }}
        <IconSvg
          v-if="copied"
          name="check"
        />
      </Button>
    </div>
    <br>
  </div>
</template>
<script lang="ts">

import Vue from 'vue'

import Button from "@/components/atoms/Button.vue";
import IconSvg from "@/components/atoms/IconSvg.vue";
import InputEmail from "@/components/atoms/InputEmail.vue";
import InputSelect from "@/components/atoms/InputSelect.vue";
import InputText from "@/components/atoms/InputText.vue";


export default Vue.extend({
  components: {InputEmail, IconSvg, Button, InputText, InputSelect},
  data(){
    return {
      recipient: null,
      copied: false,
      newUser: {
        firstName: '',
        lastName: '',
        email: '',
      }
    }
  },
  computed: {
	isProjectPerformancePage(){
		return this.$route.name === 'project-performance'
	},
    teamMembers(){
      return this.$store.getters['collaboration/forSelectedAccount'].map((collab) => {
        return {
          name: `${collab.user.firstName || ''} ${collab.user.lastName || ''} — ${collab.user.email}`,
          value: collab.user.authCode
        }
      }).concat([{
        name: 'New team member',
        value: null
      }])
    },
    link(){
      if(this.recipient && this.recipient.value){
        return `${window.location.href}?u=${this.recipient.value}`
      }
      return null;
    },
	projectPerformanceLink(){
		if(!this.isProjectPerformancePage) return false
		const url = new URL(window.location.href);
		// get the domain and protocol
		const domain = `${url.protocol}//${url.host}`;
		const path = this.$router.resolve({name: 'share-project', params: {projectId: this.$route.params.projectId}}).href
		return `${domain}${path}`
	},
    showNewUserForm(){
      return this.recipient && !this.recipient.value;
    }
  },
  methods: {
    copy(link){
      this.$root.copyToClipboard(link)
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000)
    },
    async createCollaboration(){
      const stuff = await this.$store.dispatch('collaboration/invite', this.newUser)
      console.log({stuff});
      if(stuff && stuff.local){
        this.recipient = {
          name: `${stuff.local.firstName} ${stuff.local.lastName}`,
          value: stuff.local.authCode
        }
      }
    }
  }
})

</script>
<style lang="less" scoped>
@import '../../less/mixins';
@import '../../less/variables';
@import '../../less/form-layout';

.link-container{
  background: @grayLight;
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 1em 0 0;
  border: 2px solid @black;
  input{
    margin: 0;
    text-align: center;
    color: saturate(darken(@blue, 15%), 100%);
    background: saturate(lighten(@blue, 46%), 10%);
    font-size: 0.9rem;
  }
  button{
    margin: 0;
    border-radius: 0;
  }
}
</style>
