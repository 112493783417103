import BaseModule from '@/store/base-module'

export default BaseModule({
  name: 'subscriber',
  state: {
	subscriber: null
  },
  mutations: {
	SET_SUBSCRIBER(state, subscriber) {
		state.subscriber = subscriber
	}
  },
  async beforeSave(context, subscriber) {
	context.commit('SET_SUBSCRIBER', subscriber)
  },
  getters: {
	isSubscribed: (state) => {
		return !!state.subscriber
	}
  }
})