import axios from 'axios';
import Vue from 'vue';

const axiosWithoutCredentials = axios.create({
	withCredentials: false
});

type SubscribePayload = {
	scopes: string[]
	eid?: string
	url?: string
}

export default {
  name: 'creatorVision',
  namespaced: true,
  state: {
    profiles: {},
	mediaOutliers: {},
	mediaErrors: {},
	loading: new Set()
  },
  mutations: {
    SET_PROFILES(state, profiles){
	  profiles.forEach(profile => {
		Vue.set(state.profiles, profile.eid, profile)
	  })
    },
	SET_PROFILE(state, profile){
		Vue.set(state.profiles, profile.eid, profile)
	},
	SET_MEDIA_OUTLIERS(state, mediaOutliers){
		mediaOutliers.forEach(mediaOutlier => {
			Vue.set(state.mediaOutliers, mediaOutlier.visionId, mediaOutlier)
		})
	},
	SET_MEDIA_ERRORS(state, mediaErrors){
		Vue.set(state, 'mediaErrors', mediaErrors)
	},
	SET_LOADING_START(state, keyword){
		state.loading.add(keyword)
	},
	SET_LOADING_END(state, keyword){
		state.loading.delete(keyword)
	}
  },
  actions: {
    async loadProfiles(context){
		context.commit('SET_LOADING_START', 'profiles')
		const creatorVisionServer = context.rootState.app.creatorVisionServer;
      try{
		const response = await axiosWithoutCredentials.get(context.rootState.app.creatorVisionServer + '/profiles')
        context.commit('SET_PROFILES', response.data)
		context.commit('SET_LOADING_END', 'profiles')
      } catch(e){
        console.error(e);
      }
    },
	async loadMediaOutliers(context, factor = 'by-average-views') {
		context.commit('SET_LOADING_START', factor)
		try {
			let allData = [];
			let currentPage = 1;
			let hasNextPage = true;
		
			while (hasNextPage) {
				const response = await axios.get('/api/media/outliers', {
					params: {
						factor,
						page: currentPage
					}
				});
				
				allData = [...allData, ...response.data.data];
				context.commit('SET_MEDIA_OUTLIERS', allData);
				
				hasNextPage = response.data.pagination.nextPage;
				currentPage++;
			}
		} catch(e) {
			console.error(e)
		}
		context.commit('SET_LOADING_END', factor)
	},
	async loadMediaOutliersForAccount(context, account){
		context.commit('SET_LOADING_START', account)
		try{
			const response = await axios.get('/api/media/outliers?account=' + account)
			context.commit('SET_MEDIA_OUTLIERS', response.data.data)
		} catch(e){
			console.error(e)
		}
		context.commit('SET_LOADING_END', account)
	},
	async loadMediaSnapshots(context, mediaId){
		try{
			context.commit('SET_LOADING_START', mediaId)
			const response = await axios.get('/api/media/' + mediaId)
			context.commit('SET_MEDIA_OUTLIER_SNAPSHOTS', response.data)
			context.commit('SET_LOADING_END', mediaId)
			return response.data
		} catch(e){
			console.error(e)
		}
	},
	async loadReport(context, reportRequestPayload){
      try{
		const response = await axiosWithoutCredentials.post(context.rootState.app.creatorVisionServer + '/report', reportRequestPayload)
			return response.data
		} catch(e){
			console.error(e)
		}
	},
	async loadMediaErrors(context){
		try{
			const response = await axios.get('/api/media/errors')
			context.commit('SET_MEDIA_ERRORS', response.data)
			return response.data
		} catch(e){
			console.error(e)
		}
	},
	async subscribe(context, data: SubscribePayload) {
		const creatorVisionServer = context.rootState.app.creatorVisionServer;
		try {
			const url = new URL(creatorVisionServer + '/subscribe')
			// add get params
			url.searchParams.set('subscriber', 'cv')
			url.searchParams.set('scopes', data.scopes.join(','))
			if (data.url) {
				url.searchParams.set('url', data.url)
			}
			if (data.eid) {
				url.searchParams.set('eid', data.eid)
			}
			const response = await axiosWithoutCredentials.get(url.toString())
			context.commit('SET_PROFILE', response.data.data)
		} catch (e) {
			console.error(e)
		}
	}
  },
  getters:{
	profiles: state => Object.values(state.profiles) || [],
	mediaOutliers: state => Object.values(state.mediaOutliers) || [],
	mediaOutlierTags: (state, getters) => {
		// return Array.from(new Set(getters.mediaOutliers.map(mo => mo.outlierTags).flat())).filter(Boolean)
		return []
	},
	mediaOutliersForAccount: (state, getters) => account => getters.mediaOutliers.filter(mo => mo.socialEntity.account === account),
  }
}