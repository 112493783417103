import axios from 'axios'
import {firstBy} from "thenby";
import Vue from 'vue';

import { Creator } from "@/store/account.d";
import BaseModule from '@/store/base-module'

export default BaseModule({
  name: 'accounts',
  state: {
    selectedId: null,
    creatorFilterSettings: null,
    creatorSearchQuery: '',
    showCount: 15,
    creatorView: { icon: 'hamburger', label: 'Compact View', value:'compact' },
    viewOptions: [ { icon: 'card-with-header', label: 'Full View', value:'full'}, { icon: 'hamburger', label: 'Compact View', value:'compact' } ],
    sortOptions: [
		{
		  name: 'Most Followers',
		  method: (a,b) => b.followers - a.followers
		},

		{
			name: 'Most Followers (YouTube)',
			method: (a,b) => b.followersYoutube - a.followersYoutube
		},

		{
			name: 'Most Followers (Instagram)',
			method: (a,b) => b.followersInstagram - a.followersInstagram
		},
		{
			name: 'Most Followers (TikTok)',
			method: (a,b) => b.followersTikTok - a.followersTikTok
		},
      {
        name: 'Name',
        method: (a, b) => a.name.localeCompare(b.name)
      },
      {
        name: 'Lowest price',
        method: (a, b) => {
          if (a.price === 0 && b.price === 0) return 0; // both records have a price of 0, so they are equal
          if (a.price === 0) return 1; // if only a has a price of 0, push it to the end
          if (b.price === 0) return -1; // if only b has a price of 0, push it to the end
          return Number(a.price) - Number(b.price); // if both have non-zero prices, sort based on price
        }
      },
      {
        name: 'Recently Added',
        method: (a,b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      }
    ],
    sortMethod: {
      name: 'Lowest price',
      method: (a, b) => {
        if (a.price === 0 && b.price === 0) return 0; // both records have a price of 0, so they are equal
        if (a.price === 0) return 1; // if only a has a price of 0, push it to the end
        if (b.price === 0) return -1; // if only b has a price of 0, push it to the end
        return Number(a.price) - Number(b.price); // if both have non-zero prices, sort based on price
      }
    }
  },
  mutations: {
    SET_SELECTED_ACCOUNT (state, id) {
      state.selectedId = id
    },
    SET_CREATOR_FILTER_SETTINGS (state, creatorFilterSettings){
      Vue.set(state, 'creatorFilterSettings', creatorFilterSettings)
    },
    SET_CREATOR_SEARCH_QUERY (state, creatorSearchQuery){
      Vue.set(state, 'creatorSearchQuery', creatorSearchQuery)
    },
    SET_CREATOR_VIEW (state, creatorView){
      state.creatorView = creatorView
    },
    SET_SORT_METHOD(state, sortMethod){
      state.sortMethod = sortMethod;
    },
    SET_SHOW_COUNT(state, value){
      state.showCount = value;
    }
  },
  actions: {
    async loadCreators (context) {
      const response = await axios.get('/api/accounts/creators')
      if (response && response.data) {
        context.commit('CREATE_BULK', response.data)
        return response.data
      } else {
        throw response
      }
    },
    async loadCreatorsForProject (context, projectId) {
      const response = await axios.get(`/api/accounts/creators/${projectId}`)
      if (response && response.data) {
        context.commit('CREATE_BULK', response.data)
        return response.data
      } else {
        throw response
      }
    },
    async loadBrands (context) {
      const response = await axios.get('/api/accounts/brands')
      if (response && response.data) {
        context.commit('CREATE_BULK', response.data)
        return response.data
      } else {
        throw response
      }
    },
    subscribe (context, planId) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'put',
          url: `/api/accounts/${context.getters.selected._id}/subscription`,
          data: {
            planId
          }
        })
          .then(response => {
            if (response.data && response.data._id) {
              context.commit('SET', response.data)
              resolve(response.data)
            } else {
              reject(response.data)
            }
          })
          .catch(() => {
            reject()
          })
      })
    },
    async addRecentlyViewedCreator(context, creatorId){
      let recentlyViewedCreators = context.getters.selected.recentlyViewedCreators;
      if(!recentlyViewedCreators){
        recentlyViewedCreators = [];
      }
      recentlyViewedCreators.unshift(creatorId);
      const account = {
        _id: context.getters.selected._id,
        // Only keep 10 unique creators in the array
        recentlyViewedCreators: [...new Set(recentlyViewedCreators)].slice(0, 10)
      }
      return context.dispatch('update', account);
    },
    hasAny: async function (context) {
      if (context.getters.brandsForUser.length > 0) {
        return true;
      }
      await context.dispatch('load');
      return context.getters.brandsForUser.length > 0;
    }
  },
  getters: {
    hasAny: (state, getters) => {
      return getters.brandsForUser.length > 0;
    },
    selected: (state, getters) => {
      const selected = getters.all.find(account => account._id === state.selectedId)
      return selected || {}
    },
    isBrand: (state, getters) => {
      return getters.selected.type === 'brand'
    },
    isCreator: (state, getters) => {
      return getters.selected.type === 'creator'
    },
    isCustomer: (state) => {
      return !!state.selected.stripeCustomerId
    },
    creators: (state, getters) => {
      return getters.all.filter(account => account.type === 'creator') || []
    },
    brands: (state, getters) => {
      return getters.all.filter(account => account.type === 'brand') || []
    },
    forUser: (state, getters, rootState, rootGetters) => {
      const user = rootGetters['user/me'];
	  const isAdmin = rootGetters['user/isAdmin'];
      if(user && user.accounts){
        if(isAdmin){
          // all accounts
          return getters.brands
        }else{
          // filter by collaborations
          const accountIds = user.accounts.map(collab => collab.account);
          return getters.all.filter(account => accountIds.indexOf(account._id) !== -1) || []
        }
      }else{
        return []
      }
    },
    brandsForUser: (state, getters) => {
      return getters.forUser.filter(account => account.type === 'brand')
    },
    isFiltered: state => {
      const filter = {...state.creatorFilterSettings};
      return Object.values(filter).flat(1).filter(value => value).length > 0 || !!state.creatorSearchQuery
    },
    creatorsFiltered: (state, getters, rootState, rootGetters) => {
      const searchQuery = state.creatorSearchQuery.toLowerCase();
      const filter = {...state.creatorFilterSettings};
      if(filter && filter.platforms){
        filter.platforms = filter.platforms.map(platform => platform.toLowerCase());
      }
	  console.log({filter});
      return getters.creators
        .filter(creator => {
          if(filter){
            if(filter.platforms && filter.platforms.length > 0) {
              const creatorsSocialEntities = rootGetters['socialEntity/accounts'][creator._id];
              if(!creatorsSocialEntities || !creatorsSocialEntities.some(socialEntity => filter.platforms.includes(socialEntity.platform))){
                return false;
              }
            }
            if(filter.contentTypes && filter.contentTypes.length > 0 && creator.contentTypes && !creator.contentTypes.some(contentType => filter.contentTypes.includes(contentType))) {
              return false
            }
            if(filter.contentTopics && filter.contentTopics.length > 0 && creator.topics && !creator.topics.some(topic => filter.contentTopics.includes(topic))) {
              return false
            }
            if(filter.country && filter.country.length > 0 && !filter.country.includes(creator.country)) {
              return false
            }
            if(filter.followers && !(creator.followers >= filter.followers.low && creator.followers <= filter.followers.high)) {
              return false
            }
            if(filter.price && !(creator.price >= filter.price.low && creator.price <= filter.price.high)) {
              return false
            }
			if(!rootGetters['user/canManage']){
				// Hard-code to only show MI Creators for non-admins
				filter.verticals = ['MI Creators']
			}
			if(filter.verticals && filter.verticals.length > 0 && !(creator.verticals || []).some(vertical => filter.verticals.includes(vertical))){
				return false
			}
          }
          if(searchQuery){
            return `${creator.name}\n${creator.legalName}\n${creator.youtube}\n${creator.tiktok}\n${creator.instagram}\n${creator.email}\n${creator.country}\n${creator.state}\n${creator.city}\n${creator.contentTypes.join("/n")}\n${creator.topics.join("/n")}`.toLowerCase().includes(searchQuery)
          }
          if(!creator.sponsoredContent){
            return false;
          }
          return true;
        })
        .sort(
            firstBy((a: Creator) => {
              if(searchQuery === 'guitar'){
                // assume that we're in the tour, and make sure No Fear Guitar Gear is #1
                return a._id === '652eb441ea28b5268cbd1776' ? -1 : 1;
              }
              return 0;
            })
            .thenBy((a: Creator, b: Creator) => {
              return Number(!!b.sponsoredContent) - Number(!!a.sponsoredContent)
            })
            .thenBy(state.sortMethod.method)
        )
    },
    creatorsFilteredAlternativeResults: (state, getters, rootState, rootGetters) => {
      const searchQuery = state.creatorSearchQuery.toLowerCase();

      // Create a set for faster lookup
      const filteredCreatorsSet = new Set(getters.creatorsFiltered);

      return getters.creators
          .filter(creator => {
            // Check if creator is in filteredCreatorsSet
            if (filteredCreatorsSet.has(creator)) return false;

			// Hard-code to only show music verticals
			if(!(creator.verticals || []).includes('Music') && !rootGetters['user/isAdmin']){
				return false
			}

            // If there's a search query, filter based on it
            if (searchQuery) {
              const creatorStr = `${creator.name}\n${creator.youtube}\n${creator.tiktok}\n${creator.instagram}\n${creator.email}\n${creator.country}\n${creator.state}\n${creator.city}`.toLowerCase();
              return creatorStr.includes(searchQuery);
            }

            return true;
          })
          .sort(
              firstBy((a: Creator, b: Creator) => {
                return Number(!!b.sponsoredContent) - Number(!!a.sponsoredContent);
              })
                  .thenBy(state.sortMethod.method)
          );
    },
	countries: (state, getters) => {
		const defaultCountries = [
			'United States',
			'Canada'
		];
		const countries = new Set(
			getters.all
				.filter(account => account.type === 'creator' && account.country && !defaultCountries.includes(account.country))
				.map(account => account.country)
				.sort((a, b) => a.localeCompare(b))
		);
		return [
			...defaultCountries,
			...Array.from(countries)
		];
	}
  },
  onDeauthenticate (state) {
    state.selectedId = null
  },
  async beforeSave(context, doc) {
    if (!doc._id){
      // New account created
      if(!doc.createdByUser) {
        doc.createdByUser = context.rootGetters['user/me']._id
      }
      if (!doc.createdByAccount) {
        doc.createdByAccount = context.rootGetters['account/selected']._id
      }
    }
  },
  async onCreate (context) {
    console.log('account created. refreshing user');
    await context.dispatch('user/loadMe', null, {root: true})
    await context.dispatch('collaboration/load', null, {root: true})
  }
})
